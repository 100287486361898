



























































import { Component, Vue, Watch } from "vue-property-decorator";

import { articleTypes } from "@/libs/appconst";

@Component
export default class TerminalLogList extends Vue {
  pagerHeader: any = {
    title: "充电宝日志",
    desc: "充电宝日志",
    breadcrumb: ["设备管理", "充电宝日志"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 20,
    keywords: "",
    sortDirection: null,
    sortField: null
  };
  searchReason: any[] = [];
  tableData: any = {
    items: [],
    totalCount: 0
  };
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.getData();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.getData();
  }
  async getData() {
    this.loading = true;
    try {
      var url = "/api/services/app/Terminal/GetPagedLogs";
      if (this.searchReason.length > 0) {
        url += "?";
        this.searchReason.forEach((item, index) => {
          url += "reasons=" + item;
          if (this.searchReason.length - 1 != index) {
            url += "&";
          }
        });
      }
      let res = await this.$ajax.get(url, {
        params: {
          ...this.filter
        }
      });

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }
  search() {
    this.filter.page = 1;
    this.getData();
  }
  activated() {
    if (this.$route.query.terminalId) {
      this.filter.keywords = this.$route.query.terminalId;
    }
    this.getData();
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    if (this.$route.query.terminalId) {
      this.filter.keywords = this.$route.query.terminalId;
    }
    this.getData();
  }
  get reasonOptions() {
    return this.getEnum("ChargingBatteryTerminalStatusChangeReason");
  }
  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.getData();
  }
}
